export enum DocumentGradedState {
  accepted = "accepted",
  incomplete = "incomplete",
}

export const DocumentGradedStateLabels = {
  [DocumentGradedState.accepted]: "Accepted",
  [DocumentGradedState.incomplete]: "Incomplete",
};

export const DocumentGradedStateOptions = [
  {
    value: DocumentGradedState.accepted,
    label: DocumentGradedStateLabels[DocumentGradedState.accepted],
  },
  {
    value: DocumentGradedState.incomplete,
    label: DocumentGradedStateLabels[DocumentGradedState.incomplete],
  },
];
