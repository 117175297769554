export const useShowDocumentGradedState = (
  submittedDocument: SubmittedDocument
) => {
  if (submittedDocument.documentSubmissionType == undefined) return false;

  if (submittedDocument.state != DocumentState.graded) return false;

  return (
    ([
      DocumentSubmissionType.keyWordOutline,
      DocumentSubmissionType.roughDraft,
    ].includes(submittedDocument.documentSubmissionType) ||
      submittedDocument.allowFinalDraftGradedState == true) &&
    submittedDocument.documentGradedState != undefined
  );
};
